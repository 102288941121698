import { useState, useEffect } from 'react'
import anime from 'animejs'
import { Box, CSSObject } from '@chakra-ui/react'
import { nanoid } from 'nanoid'

import { ReactComponent as Image } from './ImageLeft.svg'
import { ANIME_CONFIG } from './constants'

interface Props {
  animate?: boolean
  sx?: CSSObject
}

function ImageLeft({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          duration: 500,
          endDelay: 500,
          targets: `#animate-${id} #ellipsis *`,
          translateY: ['50%', '0%'],
          opacity: [0, 1],
          delay: anime.stagger(200),
          direction: 'normal',
        }),
      )

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 0,
          targets: `#animate-${id} #shoe`,
          duration: 500,
          transformOrigin: ['100% 50%', '100% 50%'],
          rotate: ['0deg', '-10deg'],
        }),
      )

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 0,
          targets: `#animate-${id} #hand`,
          duration: 500,
          transformOrigin: ['100% 50%', '100% 50%'],
          rotate: ['0deg', '-10deg'],
          loop: false,
          complete: (anim) => {
            anim.duration = anime.random(3, 8) * 100
            anim.delay = anime.random(50, 200)
            anim.restart()
          },
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
        },
      }}
    >
      <Image />
    </Box>
  )
}
export default ImageLeft
