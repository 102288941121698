import { useState, useEffect } from 'react'
import anime from 'animejs'
import { Box, CSSObject } from '@chakra-ui/react'
import { nanoid } from 'nanoid'

import { ReactComponent as Image } from './background.svg'
import { ANIME_CONFIG } from './constants'

interface Props {
  animate?: boolean
  dataSet?: number
  sx?: CSSObject
}

const DATA_SET = [
  [
    'M36.8,-63.6C48.2,-57.2,58.2,-48.3,67.9,-37.3C77.5,-26.3,86.8,-13.1,84.5,-1.3C82.3,10.5,68.4,21,60.6,35.1C52.7,49.3,50.8,67,41.7,75.3C32.6,83.7,16.3,82.7,1.7,79.7C-12.9,76.8,-25.7,71.8,-39.8,66.3C-53.9,60.8,-69.1,54.8,-76.8,43.6C-84.4,32.5,-84.5,16.2,-84.1,0.2C-83.7,-15.8,-82.9,-31.6,-76.5,-44.8C-70,-58.1,-57.9,-68.7,-44.2,-73.7C-30.5,-78.7,-15.3,-78.1,-1.3,-75.9C12.7,-73.8,25.5,-70,36.8,-63.6Z',
    'M32.5,-56C43.7,-50,55.2,-44.2,66.1,-34.9C77,-25.7,87.2,-12.8,89.9,1.5C92.5,15.8,87.4,31.7,78.8,44.9C70.1,58.1,58,68.7,44.3,76.2C30.5,83.6,15.3,87.9,0.8,86.5C-13.8,85.2,-27.5,78.4,-41.9,71.3C-56.3,64.3,-71.3,57.1,-78.2,45.2C-85,33.2,-83.7,16.6,-81.9,1C-80.1,-14.6,-78,-29.2,-69.5,-38.2C-61,-47.3,-46.2,-50.9,-33.6,-56.1C-20.9,-61.3,-10.5,-68.1,0.1,-68.4C10.7,-68.6,21.4,-62.1,32.5,-56Z',
    'M40.7,-70.4C51.6,-64.2,58.5,-51,65,-38.1C71.4,-25.2,77.3,-12.6,78.7,0.8C80,14.1,76.8,28.3,69.5,39.8C62.3,51.3,51.1,60.1,38.8,64.3C26.5,68.5,13.3,67.9,-0.7,69.1C-14.6,70.3,-29.3,73.2,-43.7,70.3C-58.1,67.4,-72.4,58.7,-80.2,46C-88,33.3,-89.5,16.7,-88.8,0.4C-88.1,-15.9,-85.3,-31.7,-77.5,-44.5C-69.7,-57.3,-57,-66.9,-43.2,-71.4C-29.5,-75.9,-14.7,-75.3,0.1,-75.4C14.9,-75.6,29.8,-76.5,40.7,-70.4Z',
    'M43.7,-76.1C56.3,-68.3,66.1,-56.1,72.2,-42.6C78.3,-29.2,80.7,-14.6,81.2,0.2C81.6,15.1,79.9,30.1,72.9,41.9C65.8,53.6,53.2,62,40.1,70.8C27.1,79.5,13.5,88.6,0.9,87.1C-11.8,85.6,-23.5,73.4,-35.1,63.7C-46.7,54.1,-58,47.1,-68,36.9C-77.9,26.7,-86.5,13.3,-89.2,-1.5C-91.8,-16.4,-88.5,-32.8,-77.9,-42C-67.4,-51.2,-49.6,-53.1,-35.4,-60C-21.2,-66.9,-10.6,-78.7,2.5,-83C15.5,-87.2,31,-83.9,43.7,-76.1Z',
  ],
  [
    'M35.5,-68.2C44.1,-56.6,47.7,-43.1,56.1,-31.4C64.5,-19.7,77.8,-9.9,78.6,0.4C79.4,10.8,67.6,21.5,55.8,27.3C43.9,33.1,32,33.9,22.7,41C13.4,48.1,6.7,61.5,-0.3,62C-7.2,62.4,-14.5,50,-24.7,43.5C-35,36.9,-48.4,36.3,-56.8,30C-65.2,23.8,-68.7,11.9,-70,-0.7C-71.2,-13.3,-70.3,-26.7,-62.9,-34.6C-55.4,-42.6,-41.5,-45.1,-29.9,-55C-18.3,-64.8,-9.2,-82,2.2,-85.8C13.5,-89.5,27,-79.8,35.5,-68.2Z',
    'M37.2,-69.1C42.7,-61.4,37.7,-40.1,39.2,-26.3C40.8,-12.6,48.9,-6.3,52.8,2.3C56.7,10.8,56.5,21.6,52.8,31.7C49.1,41.8,42.1,51.2,32.7,57.5C23.3,63.9,11.7,67.2,-2.3,71.1C-16.2,75.1,-32.4,79.7,-43.6,74.3C-54.7,69,-60.6,53.7,-59.8,39.7C-59,25.6,-51.3,12.8,-50.8,0.2C-50.4,-12.3,-57.2,-24.7,-58.2,-38.8C-59.1,-53,-54.1,-69.1,-43.4,-73.8C-32.8,-78.5,-16.4,-71.8,-0.2,-71.4C15.9,-71,31.8,-76.8,37.2,-69.1Z',
    'M27.4,-55.7C33.2,-44,34.1,-32.1,42.5,-22.8C51,-13.4,67,-6.7,66.7,-0.2C66.5,6.4,49.9,12.8,40.2,20C30.5,27.1,27.6,35,22.1,40.9C16.5,46.8,8.3,50.6,0.2,50.2C-7.8,49.8,-15.6,45.1,-25.4,41.7C-35.1,38.3,-46.9,36.1,-59.4,29.3C-71.9,22.6,-85,11.3,-87.7,-1.5C-90.4,-14.4,-82.6,-28.8,-73.7,-41.7C-64.8,-54.7,-54.9,-66.2,-42.4,-74.1C-29.9,-82,-15,-86.2,-2.1,-82.6C10.8,-78.9,21.5,-67.4,27.4,-55.7Z',
    'M31.8,-59.7C37.7,-51.6,36.8,-36.1,37.4,-24.9C38,-13.8,40.2,-6.9,39.8,-0.3C39.3,6.3,36.1,12.7,33.8,21C31.6,29.4,30.3,39.7,24.8,46C19.3,52.3,9.6,54.4,-2.3,58.4C-14.2,62.4,-28.5,68.2,-37,63.7C-45.5,59.2,-48.3,44.3,-50.2,32C-52.1,19.7,-53.2,9.8,-52.8,0.2C-52.4,-9.4,-50.7,-18.8,-49.2,-32C-47.8,-45.2,-46.8,-62.2,-38.6,-69.1C-30.5,-75.9,-15.2,-72.6,-1.2,-70.6C12.9,-68.6,25.8,-67.8,31.8,-59.7Z',
  ],
  [
    'M34.6,-59.9C44,-54.5,50.1,-43.5,59.8,-32.6C69.6,-21.6,82.9,-10.8,80.5,-1.4C78,8,59.8,16,48.8,24.7C37.7,33.3,33.9,42.6,26.9,50.1C20,57.6,10,63.2,-2.8,68C-15.6,72.8,-31.1,76.8,-39.1,70C-47.1,63.1,-47.5,45.4,-53.6,31.9C-59.8,18.4,-71.6,9.2,-74.1,-1.4C-76.6,-12.1,-69.7,-24.2,-62.9,-36.5C-56.1,-48.8,-49.3,-61.2,-38.8,-66C-28.4,-70.7,-14.2,-67.8,-0.8,-66.4C12.6,-65,25.2,-65.3,34.6,-59.9Z',
    'M35,-64.6C41.9,-56.7,41.6,-40.3,43,-28.1C44.5,-15.9,47.7,-7.9,51.8,2.4C55.9,12.7,60.8,25.3,57.6,34.5C54.5,43.7,43.1,49.4,32.2,49.4C21.2,49.5,10.6,43.8,0.8,42.5C-9.1,41.2,-18.2,44.2,-28.4,43.8C-38.6,43.3,-50,39.3,-60.6,31.5C-71.3,23.6,-81.2,11.8,-83.1,-1.1C-85,-14,-78.8,-28,-68.4,-36.3C-58.1,-44.6,-43.5,-47.3,-31.4,-52.3C-19.3,-57.2,-9.6,-64.3,2.2,-68.1C14.1,-72,28.1,-72.5,35,-64.6Z',
    'M38.1,-72C44.2,-62.4,40.6,-41.9,39.7,-28.1C38.8,-14.3,40.5,-7.1,43.8,1.9C47.1,10.9,51.9,21.9,52.9,35.7C53.9,49.6,51,66.5,41.5,70.7C32.1,75,16,66.6,3.9,59.8C-8.2,53,-16.3,47.7,-24.7,42.8C-33.1,38,-41.8,33.5,-52.7,26.5C-63.5,19.4,-76.7,9.7,-75.5,0.7C-74.3,-8.3,-58.7,-16.6,-51.7,-30.4C-44.7,-44.1,-46.2,-63.3,-39,-72.4C-31.7,-81.4,-15.9,-80.3,0,-80.4C15.9,-80.4,31.9,-81.6,38.1,-72Z',
    'M35.8,-64.8C44,-57.2,46.7,-42.9,56.1,-30.9C65.5,-19,81.6,-9.5,84,1.4C86.4,12.2,74.9,24.4,61.8,30C48.7,35.5,34,34.4,23.4,41C12.8,47.6,6.4,62,-0.9,63.6C-8.2,65.1,-16.3,53.8,-29.9,48.9C-43.5,44,-62.5,45.5,-66.4,38.4C-70.2,31.4,-58.9,15.7,-55.6,1.9C-52.3,-11.9,-57,-23.7,-56.3,-36.3C-55.6,-48.8,-49.5,-62,-39.2,-68.4C-28.9,-74.7,-14.5,-74.3,-0.3,-73.7C13.8,-73.2,27.6,-72.4,35.8,-64.8Z',
  ],
]

function Background({
  animate = true,
  dataSet,
  sx,
}: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      const _dataSet = dataSet ?? anime.random(0, DATA_SET.length)

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 0,
          duration: () => {
            return anime.random(2, 4) * 1000
          },
          targets: `#animate-${id} #bg-left`,
          d: DATA_SET[_dataSet].map((value, index) => {
            return {
              value,
              duration: index === 0 ? 0 : undefined,
            }
          }),
          easing: 'linear',
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
        },
      }}
    >
      <Image />
    </Box>
  )
}
export default Background
