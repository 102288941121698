import { ReactElement } from 'react'
import { Box, Text } from '@chakra-ui/react'

import PdpaCore from './PdpaCore'
import { Container, Section } from './styles'

import { SectionProps } from '../../types'

type Props = {} & SectionProps

export const OtherServices = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section>
      <Box
        layerStyle="yellow-dot-right"
        sx={{
          right: ['3px'],
          top: ['-50px', '-83px'],
          w: ['55px', '87px'],
          h: ['102px', '168px'],
        }}
      />
      <Box
        id={id}
        sx={{ pos: 'relative', top: ['-55px', '-60px', '-40px', '0'] }}
      />
      <Container
        sx={{
          pt: ['99px', '182px', '182px', '243px'],
          pb: ['134px', '198px', '198px', '260px'],
        }}
      >
        <Text as="h1" textStyle="h1" sx={{ mb: ['16px', '48px'] }}>
          บริการอื่น ๆ ของเรา
        </Text>

        <PdpaCore />
      </Container>

      <Box
        layerStyle="blue-dot"
        sx={{
          left: ['5px'],
          bottom: ['5px', '-74px'],
          boxSize: ['40px', '138px'],
        }}
      />
    </Section>
  )
}
