import React from 'react'
import { Text } from '@chakra-ui/react'

import Link from '@/lib/scroll'

export const LandingPageContent = {
  whyus: {
    cards: [
      {
        icon: '/images/Landing/WhyUs/devices.svg',
        title: 'เรียนได้ทุกที่ ทุกเวลา',
        detail: 'เข้าถึงการเรียนได้ตลอดเวลา ตอบสนองบริษัทยุคนิวนอร์มอล',
      },
      {
        icon: '/images/Landing/WhyUs/advocate.svg',
        title: 'ออกแบบโดยผู้เชี่ยวชาญ',
        detail: 'หลักสูตรถูกออกแบบโดยผู้เชี่ยวชาญ PDPA ที่ผ่านการรับรอง DPO',
      },
      {
        icon: '/images/Landing/WhyUs/legal.svg',
        title: 'สอดคล้องกับกฎหมาย',
        detail: 'องค์กรสร้างความตระหนักรู้ PDPA ให้แก่พนักงานตามที่กฎหมายกำหนด',
      },
    ],
  },
  thiscoursefor: {
    lists: [
      'เจ้าของธุรกิจหรือผู้บริหารองค์กร',
      'หัวหน้างาน',
      'นักทรัพยากรบุคคล (HR)',
      'นักกฎหมายหรือนักกลยุทธ์องค์กร',
      'พนักงานทั่วไป',
      'บุคคลทั่วไปที่สนใจกฎหมายคุ้มครอง ข้อมูลส่วนบุคคล',
    ],
  },
  whatyouwillget: {
    cards: [
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-1.svg',
        title: 'Active Learning',
        detail: (
          <>
            เข้าถึงบทเรียนได้ 24 ชั่วโมง
            <br />
            เรียนได้ตามความสะดวก ดูซ้ำได้ตามต้องการ
          </>
        ),
      },
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-2.svg',
        title: 'เนื้อหาครบถ้วน',
        detail: (
          <>
            ครอบคลุมทุกหัวข้อเกี่ยวกับ PDPA
            <br />
            ให้ผู้เรียนเข้าใจและนำไปใช้ได้จริง
          </>
        ),
      },
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-3.svg',
        title: 'Certificate',
        detail: (
          <>
            เมื่อเรียนจบทั้งคอร์สผู้เรียนจะได้รับ
            <br />
            ใบประกาศนียบัตร
          </>
        ),
      },
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-4.svg',
        title: 'ความยาวเหมาะสม',
        detail: (
          <>
            ด้วยความยาวเพียงแค่ 1 ชั่วโมง
            <br />
            ทำให้ผู้เรียนมีส่วนร่วมได้มากกว่า
          </>
        ),
      },
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-5.svg',
        title: 'มีแบบทดสอบหลังเรียน',
        detail: (
          <>
            แบบทดสอบช่วยให้ผู้เรียน
            <br />
            วัดความเข้าใจด้วยตัวเองหลังเรียนจบ
          </>
        ),
      },
      {
        icon: '/images/Landing/WhatYouWillGet/whatllget-6.svg',
        title: 'PDPA Community',
        detail: (
          <>
            สิทธิเข้า PDPA Private Group ฟรี !
            <br />
            เพื่อปรึกษาเรื่อง PDPA ได้ทุกเวลา
          </>
        ),
      },
    ],
    lists: [
      {
        main: '7 หัวใจสำคัญของ PDPA',
      },
      {
        main: 'เคสตัวอย่างของการไม่ปฏิบัติตาม',
      },
      {
        main: 'ใครบ้างที่ต้องปฏิบัติตาม',
      },
      {
        main: 'โทษของการไม่ปฏิบัติตาม',
      },
      {
        main: 'ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่อ่อนไหว',
      },
      {
        main: 'ผู้ควบคุมข้อมูล และผู้ประมวลผลข้อมูลส่วนบุคคล',
      },
      {
        main: 'กิจกรรมประมวลผลข้อมูลส่วนบุคคล',
      },
      {
        main: 'ฐานกฎหมายในการประมวลผล',
      },
      {
        main: 'การขอความยินยอม',
      },
      {
        main: 'สิทธิของเจ้าของข้อมูล',
      },
      {
        main: 'บันทึกกิจกรรมการประมวลผล',
      },
      {
        main: 'หลักการและเช็คลิสต์สำหรับปฏิบัติตาม',
      },
      {
        main: 'แผนงานการปฏิบัติตาม PDPA',
      },
    ],
  },
  guarantee: {
    lists: [
      {
        main: 'เชี่ยวชาญด้านกฎหมายคุ้มครองข้อมูลส่วนบุคคลกฎหมายธุรกิจ กฎหมายดิจิตอล',
      },
      {
        main: 'มีประสบการณ์ในการให้คำแนะนำด้านกฎหมายคุ้มครองข้อมูลส่วนบุคคล ให้ความช่วยเหลือและแก้ไขปัญหา PDPA ให้กับองค์กรชั้นนำมากมาย',
      },
      {
        main: 'ใบรับรองวิชาชีพ',
        sub: [
          'ใบรับรองการผ่านหลักสูตรเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (C-DPO) จากAcis Professional Center',
          'เนติบัณฑิตไทย โดยเนติบัณฑิตยสภา',
          'ใบอนุญาตทนายความ โดยสภาทนายความ',
          'ใบรับรองรับลายมือชื่อและเอกสาร โดยสภาทนายความ (Notarial Services Attorney)',
        ],
      },
    ],
  },
  faq: {
    lists: [
      {
        gtmLabel: 'what is learnpdpa',
        title: 'LEARNPDPA คืออะไร',
        content:
          'LEARNPDPA ทำมาเพื่อเป็นศูนย์รวมคอร์สออนไลน์ที่ให้ความรู้องค์กรและบุคคลทั่วไปเกี่ยวกับ PDPA รวมถึงแนวปฏิบัติต่าง ๆ เพื่อให้ผู้เรียนมีความตระหนักและเตรียมการปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล รวมถึงการจัดการในด้านความปลอดภัยทางไซเบอร์ เราออกแบบให้เป็นคอร์สออนไลน์แบบ e-learning เพื่อให้สามารถเข้าถึงบทเรียนได้ทุกที่ ทุกเวลา ตามความต้องการของผู้เรียน นอกจากนั้นผู้เรียนสามารถเข้าสู่ PDPA Community เพื่อแบ่งปันกรณีศึกษาต่าง ๆ และปรึกษาผู้เชี่ยวชาญด้านกฎหมายได้ทุกเมื่อ',
      },
      {
        gtmLabel: 'non-legal friendly',
        title: 'คนทั่วไปสามารถเรียนได้หรือไม่',
        content:
          'ได้ คอร์สเรียนนี้ออกแบบมาให้เหมาะสมกับทั้งพนักงานในองค์กรและคนทั่วไปที่ต้องการศึกษาเพิ่มเติมเกี่ยวกับกฎหมายคุ้มครองข้อมูลส่วนบุคคล โดยราคาคอร์สเรียนสำหรับคนทั่วไปจะเริ่มต้นที่ 600 บาทเท่านั้น',
      },
      {
        isDisabled: true,
        gtmLabel: 'language',
        title: 'การเรียนการสอนเป็นภาษาอะไร',
        content:
          'ภาษาไทยพร้อมซับไตเติ้ลภาษาอังกฤษ คอร์สเรียนของเราทำมาเพื่อองค์กรในไทย จึงทำเป็นภาษาไทยเป็นหลัก โดยผู้สอนจะพูดเป็นภาษาไทย แต่ยังรองรับองค์กรที่มีพนักงานชาวต่างชาติโดยการให้สามารถเปิดหรือปิดซับไตเติลภาษาอังกฤษได้อีกด้วย',
      },
      {
        gtmLabel: 'validity period',
        title: 'สามารถเข้าเรียนได้นานเท่าไหร่หลังจากที่ซื้อ',
        content: '1 ปีนับจากวันที่ซื้อ โดยสามารถเข้าเรียนได้ไม่จำกัดจำนวนครั้ง',
      },
      {
        gtmLabel: 'tax',
        title: 'สามารถออกใบกำกับภาษีในนามนิติบุคคลและใบเสนอราคาได้หรือไม่',
        content: (
          <>
            ได้ เราสามารถอออกใบกำกับภาษีในนามนิติบุคคลให้คุณได้ รวมถึงใบเสนอราคา
            โดยคุณสามารถระบุรายละเอียดได้ในฟอร์ม{' '}
            <Link url={{ pathname: 'currentPath', hash: 'contact' }}>
              <Text
                as="span"
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                ติดต่อเรา
              </Text>
            </Link>
          </>
        ),
      },
    ],
  },
  summary: {
    products: [
      {
        title: 'PDPA PRO',
        description: 'สร้าง Privacy Policy ง่าย ๆ',
        image: '/images/Landing/Summary/pdpa-pro',
        url: 'https://pdpa.pro',
      },
      {
        title: 'COOKIE WOW',
        description: 'สร้างคุกกี้แบนเนอร์ใน 2 นาที',
        image: '/images/Landing/Summary/cookie-wow',
        url: 'https://cookiewow.com',
      },
      {
        title: 'PDPA FORM',
        description: 'จัดการคำร้องใช้สิทธิตาม PDPA',
        image: '/images/Landing/Summary/pdpa-form',
        url: 'https://pdpaform.com',
      },
      {
        title: 'PDPA PROKIT',
        description: 'ชุดเครื่องมือเพื่อปฏิบัติตาม PDPA',
        image: '/images/Landing/Summary/pdpa-prokit',
        url: 'https://pdpa.pro/prokit',
      },
    ],
  },
  promotions: [
    {
      value: 'ลด 50% ปรึกษานักกฎหมาย เมื่อซื้อ 30 license ขึ้นไป',
      label: 'ลด 50% ปรึกษานักกฎหมาย เมื่อซื้อ 30 license ขึ้นไป',
      minPurchase: 30,
    },
  ],
}
