import { ReactElement } from 'react'
import Image from 'next/image'
import { Grid, Text, AspectRatio, Link, Button, Box } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { Section, Container } from './styles'
import { SectionProps } from '../../types'
import * as externalLinks from '@/routes/external'

type Props = {
  blogs: Blog[]
} & SectionProps

export const Blogs = (props: Props): ReactElement => {
  const { blogs = [], id } = props

  return (
    <Section>
      <Box id={id} sx={{ pos: 'relative', top: ['-55px', '-60px', 0, 0] }} />
      <Container
        sx={{
          pt: ['95px', '95px', '305px', '305px'],
          pb: ['87px', '87px', '281px', '306px'],
        }}
      >
        <Text as="h1" textStyle="h1" sx={{ mb: ['4px', '16px', '0', '0'] }}>
          PDPA Blog
        </Text>

        <Text
          as="h2"
          sx={{
            fontWeight: 300,
            textAlign: 'center',
            maxW: ['280px', '100%'],
            mb: ['12px', '69px', '32px', '32px'],
            mx: ['auto'],
            fontSize: ['16px', '24px'],
          }}
        >
          ติดตามบทความ ความรู้เกี่ยวกับ PDPA เพิ่มเติม ที่นี่...
        </Text>

        <Grid
          sx={{
            gridTemplateColumns: [
              'unset',
              '1fr 1fr',
              'repeat(4, 1fr)',
              'repeat(4, 1fr)',
            ],
            gap: ['16px', '40px 24px', '27px', '27px'],
            mb: ['48px', '48px', '36px', '36px'],
          }}
        >
          {blogs.map((blog) => {
            return (
              <BlogItem
                key={blog.title}
                image={blog.image_1.url}
                title={blog.title}
                link={blog.link}
                alt={blog.slug}
                date={blog.published_at}
              />
            )
          })}
        </Grid>

        <Button
          as={Link}
          isExternal
          href={externalLinks.BLOGS.pathname}
          variant="outline"
          size="lg"
          sx={{
            mx: ['auto'],
            d: ['flex'],
            w: 'fit-content',
            fontSize: ['16px', '24px'],
            h: ['48px', '72px'],
            textDecoration: 'none !important',
          }}
        >
          อ่านบทความอื่นๆ
        </Button>
      </Container>
    </Section>
  )
}

type BlogItemProps = {
  title: string
  image: string
  date: string
  alt: string
  link: string
}

const BlogItem = (props: BlogItemProps): ReactElement => {
  const { title, image, date, alt, link } = props

  return (
    <Link
      sx={{
        d: 'grid',
        gap: ['16px'],
        alignContent: 'start',
        _hover: {
          textDecor: 'none',
        },
        _focus: {
          outline: 'none',
        },
        _active: {
          outline: 'none',
        },
      }}
      href={link}
      title={title}
      isExternal
    >
      <AspectRatio ratio={264 / 138}>
        <Image src={image} alt={alt} objectFit="cover" layout="fill" />
      </AspectRatio>

      <Text sx={{ fontSize: ['16px', '20px'] }}>{title}</Text>
      <Text
        sx={{
          fontWeight: 300,
          fontSize: ['14px', '16px'],
          _hover: {
            textDecor: 'none',
          },
        }}
      >
        {dayjs(date).format('DD/MM/YYYY')}
      </Text>
    </Link>
  )
}
