import {
  Box,
  Flex,
  Text,
  NumberInputProps,
  InputProps,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useTheme,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { get } from 'lodash'
import { UseFormRegister } from 'react-hook-form'

import {
  AnimatedBox,
  AnimatedInput,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'

const AnimatedNumberIncrementStepper = animated(NumberIncrementStepper)
const AnimatedNumberDecrementStepper = animated(NumberDecrementStepper)
const AnimatedNumberInput = animated(NumberInput)

interface Props {
  value?: string
  numberInputProps?: NumberInputProps
  inputProps: InputProps & {
    value?: any
    customSize?: Record<string, string>
    register: UseFormRegister<any>
    name: string
    customColor?: {
      bgColor: [string, string]
      color: [string, string]
      placeholderColor: [string, string]
      borderColor: [string, string]
    }
  }
}

function TextInput({
  value,
  numberInputProps,
  inputProps,
}: Props): React.ReactElement {
  const {
    placeholder,
    isRequired,
    customSize = {
      base: 'lg',
      md: 'xl',
    },
    customColor = {
      bgColor: ['#ffffffff', '#ffffff00'],
      color: ['primary.800', 'blue.400'],
      placeholderColor: ['gray.400', 'white'],
      borderColor: ['white', 'white'],
    },
    sx,
    register,
    ...rest
  } = inputProps

  const { ref: registeredRef, ...registeredProps } = register(inputProps.name)

  const theme = useTheme()

  const size = useBreakpointValue(customSize)

  const fieldStyle = theme.components.Input.sizes[size as string]?.field
  const height = fieldStyle?.h || 10

  const color = [
    get(theme.colors, customColor.color[0], customColor.color[0]),
    get(theme.colors, customColor.color[1], customColor.color[1]),
  ]
  const placeholderColor = [
    get(
      theme.colors,
      customColor.placeholderColor[0],
      customColor.placeholderColor[0],
    ),
    get(
      theme.colors,
      customColor.placeholderColor[1],
      customColor.placeholderColor[1],
    ),
  ]
  const bgColor = [
    get(theme.colors, customColor.bgColor[0], customColor.bgColor[0]),
    get(theme.colors, customColor.bgColor[1], customColor.bgColor[1]),
  ]

  const [isFocused, setFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const showPlaceholderAsLabel = Boolean(isFocused || value?.toString())

  const spring = useSpring({
    color: showPlaceholderAsLabel ? color[1] : color[0],
    placeholderColor: showPlaceholderAsLabel
      ? placeholderColor[1]
      : placeholderColor[0],
    fontSize: showPlaceholderAsLabel
      ? get(theme.fontSizes, fieldStyle?._placeholder?.fontSize)
      : get(theme.fontSizes, fieldStyle?.fontSize),
    transform: showPlaceholderAsLabel
      ? 'translate3d(0, -100%, 0)'
      : 'translate3d(0, -0%, 0)',
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <Box
      sx={{
        h: 'fit-content',
        position: 'relative',
        borderRadius: 'base',
        height,
      }}
    >
      <HStack
        spacing="0"
        sx={{
          ...fieldStyle,
          position: 'absolute',
          top: '0',
          left: '0',
          px: 0,
          alignItems: 'stretch',
          w: 'full',
          height,
        }}
      >
        <Box
          style={{
            minWidth: `calc(${fieldStyle?.px} - 6px)`,
            maxWidth: `calc(${fieldStyle?.px} - 6px)`,
            flex: `0 0 calc(${fieldStyle?.px} - 6px)`,
          }}
          sx={{
            borderTopLeftRadius: 'md',
            borderBottomLeftRadius: 'md',
            border: '1px solid',
            borderRight: 'none',
            borderColor: customColor.borderColor,
          }}
        />
        <Flex
          sx={{
            transformOrigin: 'left center',
            flex: '0 0 auto',
            borderBottom: '1px solid',
            borderTop: showPlaceholderAsLabel ? 'none' : '1px solid',
            borderColor: customColor.borderColor,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AnimatedBox
            style={{
              color: spring.placeholderColor,
              fontSize: spring.fontSize,
              transform: spring.transform,
            }}
            onClick={() => {
              inputRef.current?.focus()
            }}
            sx={{
              transformOrigin: 'left top',
              zIndex: 1,
            }}
          >
            <Text
              sx={{
                fontSize: 'inherit',
                px: 2,
                color: 'inherit',
                fontWeight: 'light',
              }}
            >
              {placeholder}{' '}
              {isRequired && (
                <Text as="span" sx={{ color: 'red.400' }}>
                  *
                </Text>
              )}
            </Text>
          </AnimatedBox>
        </Flex>
        <Box
          sx={{
            borderTopRightRadius: 'md',
            borderBottomRightRadius: 'md',
            border: '1px solid',
            borderLeft: 'none',
            borderColor: customColor.borderColor,
            flex: '1 1 100%',
          }}
        />
      </HStack>
      <AnimatedNumberInput
        {...numberInputProps}
        value={value}
        size={size}
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          h: 'fit-content',
        }}
      >
        <AnimatedInput
          {...rest}
          as={NumberInputField}
          ref={(e) => {
            registeredRef(e)
            inputRef.current = e
          }}
          sx={{
            bgColor: 'transparent',
            transition: 'none',
            border: 'none',
            w: 'full',
            boxShadow: 'none !important',
            color: color[1],
            backgroundColor: !showPlaceholderAsLabel ? bgColor[0] : bgColor[1],
            ...sx,
          }}
          onFocus={() => {
            !inputProps.isDisabled && setFocused(true)
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            !inputProps.isDisabled && setFocused(false)
            registeredProps.onBlur(e)
          }}
          variant="ghost"
          placeholder=""
          size={size}
        />
        <NumberInputStepper>
          <AnimatedNumberIncrementStepper
            style={{ color: spring.color }}
            sx={{ border: 'none' }}
          />
          <AnimatedNumberDecrementStepper
            style={{ color: spring.color }}
            sx={{ border: 'none' }}
          />
        </NumberInputStepper>
      </AnimatedNumberInput>
    </Box>
  )
}

export default TextInput
