import { useEffect, useState } from 'react'
import { Text, VStack, Box, SimpleGrid } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'

import Flipper from './Flipper'

const PDPAApplyDate = '2022-06-01'
const applyDate = dayjs(PDPAApplyDate)

const SeparatorStyle = {
  fontSize: { base: '24px', sm: '48px', md: '72px' },
  fontWeight: 'medium',
  color: 'secondary.800',
  lineHeight: '1em',
}

const LabelStyle = {
  fontSize: { base: 'lg', md: '2xl' },
  color: 'secondary.800',
  textAlign: 'center',
  fontWeight: 'thin',
}

export const isNotExceedDate = (): boolean => {
  const now = dayjs()

  return applyDate.diff(now, 'seconds') >= 0
}

const limitCountDown = (diff: number) => {
  return Math.max(diff, 0)
}

const getDateDiff = () => {
  const now = dayjs()

  return {
    days: limitCountDown(applyDate.diff(now, 'days')),
    hours: limitCountDown(applyDate.diff(now, 'hours')) % 24,
    minutes: limitCountDown(applyDate.diff(now, 'minutes')) % 60,
    seconds: limitCountDown(applyDate.diff(now, 'seconds')) % 60,
  }
}

function PDPACountDown(): React.ReactElement {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  })

  const [dateDiff, setDateDiff] = useState(getDateDiff)

  useEffect(() => {
    if (!inView) return

    setDateDiff(getDateDiff)

    const interval = setInterval(() => {
      setDateDiff(getDateDiff)
    }, 1000)

    return () => clearInterval(interval)
  }, [inView])

  return (
    <VStack
      layerStyle="landingContainer"
      ref={ref}
      sx={{
        mt: {
          base: 24,
          md: 24,
        },
        mb: {
          base: 16,
          md: 24,
        },
      }}
    >
      <Box
        sx={{
          borderRadius: 'md',
          border: '1px solid',
          borderColor: 'inherit',
          boxShadow: 'lg',
          w: 'full',
          py: 8,
          px: { base: 4, md: 6 },
        }}
      >
        <VStack spacing="1" layerStyle="landingSectionHeading">
          <Text
            as="h1"
            textStyle="h1"
            sx={{
              fontWeight: 'medium',
              fontSize: {
                base: 'xl',
                sm: '2xl',
                md: '3.5xl',
              },
              mb: { base: 4, md: 8 },
            }}
          >
            <Text as="strong" sx={{ color: 'primary.400', fontWeight: 'bold' }}>
              พรบ.คุ้มครองข้อมูลส่วนบุคคล
            </Text>
            ของประเทศไทย กำลังจะบังคับใช้
            <Box as="br" />
            <Text as="strong" sx={{ color: 'primary.400', fontWeight: 'bold' }}>
              ภายในวันที่ 1 มิถุนายน 2565
            </Text>
          </Text>
        </VStack>
        <SimpleGrid
          sx={{
            gridTemplateColumns: 'repeat(7, max-content)',
            gridGap: { base: 2, sm: 4, md: 5 },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Flipper digit={2} value={dateDiff.days} />
          <Text sx={SeparatorStyle}>:</Text>
          <Flipper digit={2} value={dateDiff.hours} />
          <Text sx={SeparatorStyle}>:</Text>
          <Flipper digit={2} value={dateDiff.minutes} />
          <Text sx={SeparatorStyle}>:</Text>
          <Flipper digit={2} value={dateDiff.seconds} />
          <Text sx={LabelStyle}>วัน</Text>
          <Box />
          <Text sx={LabelStyle}>ชั่วโมง</Text>
          <Box />
          <Text sx={LabelStyle}>นาที</Text>
          <Box />
          <Text sx={LabelStyle}>วินาที</Text>
        </SimpleGrid>
      </Box>
    </VStack>
  )
}

export default PDPACountDown
