import { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { scroller } from 'react-scroll'

import {
  Blogs,
  Carousel,
  Contact,
  FAQ,
  OtherServices,
  Guarantee,
  Hero,
  Newsletter,
  LazySummary,
  WhatYouWillGet,
  WhyUs,
  CourseList,
  Promotions,
} from '../../containers'

import NavigationBar from '@/components/organisms/NavigationBar'
import { Footer } from '@/components/organisms/Footer'

import { COURSES } from '@/features/Courses/constants'

import { SCROLL_OPTION } from '@/lib/scroll'

type Props = {
  blogs: Blog[]
}

function Home(props: Props): React.ReactElement {
  const { blogs } = props

  const { asPath } = useRouter()

  useEffect(() => {
    const splitedAsPath = asPath.split('#')

    if (splitedAsPath.length < 2) return

    const hash = splitedAsPath[splitedAsPath.length - 1]

    setTimeout(() => {
      scroller.scrollTo(hash, SCROLL_OPTION)
    }, 500)
  }, [])

  return (
    <Box as="main">
      <NavigationBar />
      <Carousel id="top" />
      <Hero id="hero" />
      <WhyUs id="why-us" />
      <CourseList id="our-courses" courses={COURSES} />
      <WhatYouWillGet id="what-you-will-get" />
      <Guarantee id="benefits" />
      <Promotions id="promotion" />
      <Contact id="contact" />
      <FAQ id="faq" />
      <OtherServices id="other-services" />
      <Blogs blogs={blogs} id="blogs" />
      <Newsletter id="newsletter" />
      <LazySummary id="summary" />
      <Footer />
    </Box>
  )
}

export default Home
