import { useRef, useEffect, useState, Fragment } from 'react'
import Router from 'next/router'
import { AspectRatio, Box } from '@chakra-ui/react'
import { useSprings } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { scroller } from 'react-scroll'

import { AnimatedBox } from '@/lib/springComponent'
import ImageLoader from '@/components/atoms/ImageLoader'
import { SCROLL_OPTION } from '@/lib/scroll'
import { logEvent } from '@/lib/stats'
import { courseRoute } from '@/routes'

import { getPos, getIndex } from './helpers'

import CarouselBar from './CarouselBar'

const DATA = [
  {
    src: '/images/Landing/Carousel/pdpa-awareness-training-intensive.jpg',
    alt: 'PDPA Awareness Training Intensive',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'pdpa-awareness-training-intensive',
        },
      })

      Router.push('https://study.learnpdpa.com/bundles/pdpa-intensive-bundle')
    },
  },
  {
    src: '/images/Landing/Carousel/pdpa-for-dpo.jpg',
    alt: 'Courses',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'pdpa-for-dpo',
        },
      })

      Router.push({
        ...courseRoute,
        query: {
          slug: 'pdpa-for-dpo',
        },
      })
    },
  },
  {
    src: '/images/Landing/Carousel/courses.jpg',
    alt: 'Courses',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'see all courses',
        },
      })

      scroller.scrollTo('our-courses', SCROLL_OPTION)
    },
  },
  {
    src: '/images/Landing/Carousel/pdpa-awareness-training-01.jpg',
    external: true,
    alt: 'PDPA Awareness Training',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'pdpa-awareness-training',
        },
      })

      Router.push({
        ...courseRoute,
        query: {
          slug: 'pdpa-awareness-training',
        },
      })
    },
  },
  {
    src: '/images/Landing/Carousel/pdpa-awareness-training-02.jpg',
    external: true,
    alt: 'PDPA Awareness Training Part 2',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'pdpa-awareness-training-part-2',
        },
      })

      Router.push({
        ...courseRoute,
        query: {
          slug: 'pdpa-awareness-training-part-2',
        },
      })
    },
  },
  {
    src: '/images/Landing/Carousel/pdpa-awareness-training-03.jpg',
    external: true,
    alt: 'PDPA Awareness Training Part 3',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'pdpa-awareness-training-part-3',
        },
      })

      Router.push({
        ...courseRoute,
        query: {
          slug: 'pdpa-awareness-training-part-3',
        },
      })
    },
  },
  {
    src: '/images/Landing/Carousel/hr-pdpa-awareness-training.jpg',
    alt: 'HR PDPA Awareness Training',
    onClick: () => {
      logEvent({
        ga: {
          category: 'Banner',
          action: 'Click banner',
          label: 'hr-pdpa-awareness-training',
        },
      })

      Router.push({
        ...courseRoute,
        query: {
          slug: 'hr-pdpa-awareness-training',
        },
      })
    },
  },
]

const VISIBLE_RANGE = 3

interface Props {
  id?: string
}

function Carousel({ id }: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null)

  const lastMouseDown = useRef(0)

  const [index, setIndex] = useState(0)
  const prevIndex = useRef<number>(index)

  const [springs, springsApi] = useSprings(DATA.length, (idx) => {
    const [pos] = getPos(idx, index, VISIBLE_RANGE, DATA.length)

    return {
      display: pos === null ? 'none' : 'block',
      translate3d:
        pos === null
          ? `calc(${pos * 100}% + 0px), 0, 0`
          : `calc(${pos * 100}% + 0px), 0, 0`,
      scale: pos === 0 ? 1 : 0.96,
      opacity: pos === 0 ? 1 : 0.4,
    }
  })

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel, dragging }) => {
      if (!containerRef.current) return

      if (dragging) lastMouseDown.current = 0

      const boundingClientRect = containerRef.current.getBoundingClientRect()

      if (active && Math.abs(mx) > boundingClientRect.width / 3) {
        cancel()

        setIndex((currentIndex: number) =>
          getIndex(currentIndex + (xDir > 0 ? -1 : 1), DATA.length),
        )
      }

      springsApi.start((idx) => {
        const [pos, isVisible] = getPos(idx, index, VISIBLE_RANGE, DATA.length)

        if (!isVisible) {
          return {
            display: 'none',
          }
        }

        return {
          display: 'block',
          translate3d: `calc(${pos * 100}% + ${active ? mx : 0}px), 0, 0`,
        }
      })
    },
    { axis: 'x', delay: 100 },
  )

  useEffect(() => {
    springsApi.start((idx) => {
      const [pos, isVisible] = getPos(idx, index, VISIBLE_RANGE, DATA.length)
      const [prevPos] = getPos(
        idx,
        prevIndex.current,
        VISIBLE_RANGE,
        DATA.length,
      )

      if (!isVisible) {
        return {
          display: 'none',
          translate3d: `calc(${pos * 100}% + 0px), 0, 0`,
          opacity: 0,
          scale: 0,
        }
      }

      const isFlipped = Boolean(
        prevPos !== 0 && pos !== 0 && Math.sign(pos) !== Math.sign(prevPos),
      )

      return {
        display: 'block',
        translate3d: `calc(${
          (isFlipped ? pos + Math.sign(pos) : pos) * 100
        }% + 0px), 0, 0`,
        opacity: pos === 0 ? 1 : 0.4,
        scale: pos === 0 ? 1 : 0.96,
        immediate: (key) => {
          switch (key) {
            case 'translate3d':
              return isFlipped
            default:
              return false
          }
        },
        onRest: () => {
          springsApi.start((idx) => {
            if (prevIndex.current === index) {
              const [pos] = getPos(idx, index, VISIBLE_RANGE, DATA.length)

              return {
                translate3d: `calc(${pos * 100}% + 0px), 0, 0`,
              }
            }

            return {}
          })
        },
      }
    })

    prevIndex.current = index
  }, [index])

  useEffect(() => {
    const timer = setTimeout(
      () => setIndex((i) => getIndex(i + 1, DATA.length)),
      8000,
    )

    return () => clearTimeout(timer)
  }, [index])

  const length = DATA.length

  return (
    <Box
      id={id}
      sx={{
        alignItems: 'stretch',
        w: '100vw',
        h: 'fit-content',
        // pt: '100px',
        position: 'relative',
        overflowX: 'hidden',
        maxW: '300vw',
      }}
    >
      <AspectRatio
        ref={containerRef}
        layerStyle="landingContainer"
        ratio={1112 / 356}
        sx={{
          w: 'full',
          position: 'relative',
          overflowX: 'visible',
          px: '0px !important',
          cursor: 'grab',
          '&:active': { cursor: 'grabbing' },
          '& > *': {
            flex: '1 0 100%',
          },
          overscrollBehaviorX: 'contain',
          touchAction: 'pan-y',
        }}
      >
        <Fragment>
          {DATA.map((datum, idx) => {
            return (
              <AnimatedBox
                key={`${idx}`}
                sx={{
                  w: 'full',
                  h: 'full',
                  position: 'absolute',
                  '* > img': {
                    userDrag: 'none',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    WebkitUserDrag: 'none',
                    WebkitUserSelect: 'none',
                    MsUserSelect: 'none',
                    pointerEvents: 'none',
                  },
                }}
                onMouseDown={() => {
                  lastMouseDown.current = new Date().getTime()
                }}
                onMouseUp={() => {
                  if (lastMouseDown.current !== 0) {
                    datum.onClick()
                  }

                  lastMouseDown.current = 0
                }}
                style={springs[idx]}
                {...bind()}
              >
                <ImageLoader
                  src={datum.src}
                  alt={datum.alt}
                  external={datum.external}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                  }}
                />
              </AnimatedBox>
            )
          })}
        </Fragment>
      </AspectRatio>
      <AspectRatio
        ratio={1112 / 356}
        layerStyle="landingContainer"
        sx={{
          w: 'full',
          overflowX: 'visible',
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate3d(50%, 0, 0)',
          mt: '0',
        }}
      >
        <Box
          onClick={() => {
            setIndex((i) => getIndex(i + 1, DATA.length))
          }}
        />
      </AspectRatio>
      <AspectRatio
        ratio={1112 / 356}
        layerStyle="landingContainer"
        sx={{
          w: 'full',
          overflowX: 'visible',
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate3d(-150%, 0, 0)',
          mt: '0',
        }}
      >
        <Box
          onClick={() => {
            setIndex((i) => getIndex(i - 1, DATA.length))
          }}
        />
      </AspectRatio>
      <CarouselBar
        onClick={(idx) => setIndex(idx)}
        length={length}
        index={index}
        sx={{
          position: 'absolute',
          bottom: {
            base: 3,
            md: 6,
          },
          left: '50%',
          transform: 'translate3d(-50%, 0, 0)',
        }}
      />
    </Box>
  )
}

export default Carousel
