import { Box, Center, Grid, Text, useTheme } from '@chakra-ui/react'
import { ReactElement } from 'react'

import { Container, Section } from './styles'
import { LandingPageContent } from '../../constants'
import { SectionProps } from '../../types'

type Props = {} & SectionProps

export const WhyUs = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section
      id={id}
      sx={{
        bg: 'primary.25',
      }}
    >
      <Container
        layerStyle="landingContainer"
        sx={{
          pt: ['110px', '150px'],
          pb: ['182px', '280px'],
        }}
      >
        <Text
          as="h1"
          textStyle="h1"
          sx={{
            mb: ['44px', '72px', '48px', '72px'],
          }}
        >
          ทำไมต้อง LEARN PDPA
        </Text>

        <Grid
          sx={{
            gridAutoRows: ['max-content', 'max-content', 'unset', 'unset'],
            gridTemplateColumns: ['', '', 'repeat(3, 1fr)'],
            justifyContent: 'center',
            justifyItems: 'center',
            gap: ['40px', '40px', '28px', '28px'],
          }}
        >
          {LandingPageContent['whyus']['cards'].map((card) => {
            return <WhyUsCard key={card.title} {...card} />
          })}
        </Grid>
      </Container>
    </Section>
  )
}

type WhyUsCardProps = {
  icon: string
  title: string
  detail: string
}

const WhyUsCard = (props: WhyUsCardProps): ReactElement => {
  const { icon, title, detail } = props
  const theme = useTheme()

  return (
    <Grid
      sx={{
        borderRadius: '24px',
        border: '1px solid',
        borderColor: 'gray.350',
        alignItems: 'center',
        pos: 'relative',
        background: 'white',
        p: ['48px 18px', '38px 64px', '49px 16px 28px', '49px 16px 28px'],
        w: ['265px', '636px', '353px', '353px'],
        gridTemplateColumns: ['unset', '124px 1fr', 'unset', 'unset'],
        gridTemplateRows: [
          '105px max-content',
          'unset',
          '140px max-content',
          '140px max-content',
        ],
        justifyItems: ['center'],
        gap: ['24px', '36px'],
        bg: 'white',
        zIndex: '1',
        boxShadow: `0px 0px ${theme.colors.primary[500]}`,
        transition: 'box-shadow 250ms',
        _hover: {
          '.center': {
            bg: 'primary.500',
          },

          boxShadow: `8px 8px ${theme.colors.primary[500]}`,
        },
      }}
    >
      <Center
        className="center"
        sx={{
          borderRadius: '50%',
          bg: 'primary.700',
          transition: 'background-color 0.25s ease',
          boxSize: ['105px', '124px', '140px', '140px'],
        }}
      >
        <Box
          layerStyle="background"
          sx={{
            backgroundImage: `url(${icon})`,
            boxSize: 'full',
          }}
        />
      </Center>
      <Box
        sx={{
          d: ['block'],
          textAlign: ['center', 'start', 'center', 'center'],
        }}
      >
        <Text
          sx={{
            mb: ['16px', '0', '17px', '17px'],
            fontSize: ['18px', '24px'],
            color: 'black',
          }}
        >
          {title}
        </Text>

        <Text sx={{ fontWeight: 300, fontSize: ['16px', '20px'] }}>
          {detail}
        </Text>
      </Box>
    </Grid>
  )
}
