import { Box, chakra, Container as CKContainer } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    w: '100%',
    pos: 'relative',
    bg: 'primary.25',
  },
})

export const Container = chakra(CKContainer, {
  baseStyle: {
    w: '80%',
    maxW: '1113px',
  },
})
