import { Box, chakra, Container as CKContainer } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    w: '100%',
    bg: ['primary.25', 'primary.25', 'unset', 'unset'],
    pos: 'relative',
    '.blue-dot': {
      pos: 'absolute',
      boxSize: ['94px', '151px'],
      left: ['8px'],
      top: ['-48px', '-74px'],
    },
    '.blue-bg': {
      bg: 'primary.25',
      h: ['', '', '805px', '878px'],
      pos: 'absolute',
      w: '69vw',
      right: 0,
      top: '-10px',
      zIndex: -1,
      borderRadius: '16px 0 0 16px',
    },
  },
})

export const Container = chakra(CKContainer, {
  baseStyle: {
    w: ['80%', '80%', '90%', '90%'],
    maxW: '1113px',
  },
})
