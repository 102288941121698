import React, { useRef, useState, useEffect } from 'react'
import { Skeleton } from '@chakra-ui/react'

interface Props {
  src: string
  srcSet?: string
  klass?: string
  forceActive?: boolean
  external?: boolean
  alt?: string
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
}

function ImageLoader({
  src,
  srcSet,
  klass,
  forceActive = false,
  external = false,
  alt = '',
  width,
  height,
  style,
}: Props): React.ReactElement {
  const imageRef = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!!!IntersectionObserver) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsActive(true)
          observer.disconnect()
        }
      },
      {
        rootMargin: '200px 0px',
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    )

    if (imageRef.current) {
      observer.observe(imageRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isActive && external) {
      const image = new Image()
      image.src = src

      image.onload = () => {
        setIsActive(true)
      }

      image.onerror = () => {
        console.log('error loading image')
      }
    }
  }, [isActive, external, src])

  if (isActive || forceActive) {
    const webpSrcSet = srcSet ? `${srcSet.split('.')[0]}.webp 2x` : undefined
    const webpSrc = `${src.split('.')[0]}.webp`

    return (
      <Skeleton isLoaded={isActive} height="100%">
        <picture>
          {!external && srcSet && (
            <source type="image/webp" srcSet={webpSrcSet} />
          )}
          {!external && <source type="image/webp" srcSet={webpSrc} />}
          <img
            className={klass}
            alt={alt}
            src={src}
            srcSet={srcSet}
            width={width}
            height={height}
            style={style}
          />
        </picture>
      </Skeleton>
    )
  }

  return (
    <Skeleton isLoaded={isActive} height="100%">
      <div className={klass || 'ImageLoader'} ref={imageRef} />
    </Skeleton>
  )
}

export default ImageLoader
