import { Box, Button, Text, VStack, Icon } from '@chakra-ui/react'

import ScrollLink from '@/lib/scroll'
import { logEvent } from '@/lib/stats'

import { ReactComponent as Foreground } from '@/svg/landing/decorations/cta-foreground.svg'
import { ReactComponent as Background } from '@/svg/landing/decorations/cta-background.svg'

function Label(): React.ReactElement {
  return (
    <VStack spacing="10" sx={{ alignItems: 'stretch' }}>
      <Box
        sx={{
          fontSize: {
            base: 'lg',
            sm: '2xl',
            md: '4xl',
          },
        }}
      >
        <Text
          sx={{
            fontWeight: 'light',
            textAlign: 'center',
          }}
        >
          <Text as="strong" sx={{ fontWeight: 'medium' }}>
            LearnPDPA
          </Text>{' '}
          ช่วยให้องค์กรเข้าใจPDPA อย่างง่ายดาย ด้วยระบบการเรียนแบบ{' '}
          <Text as="strong" sx={{ fontWeight: 'medium' }}>
            Self-paced learning
          </Text>{' '}
          สะดวกประหยัดเวลา
        </Text>
        <Text
          sx={{
            fontWeight: 'medium',
            textAlign: 'center',
            color: 'primary.500',
          }}
        >
          ในราคาเริ่มต้นเพียง 600 บาท เท่านั้น สมัครเรียนเลย !
        </Text>
      </Box>
      <VStack spacing="4">
        <Button
          as={ScrollLink}
          url={{ pathname: 'currentPath', hash: 'our-courses' }}
          onClick={() => {
            logEvent({
              ga: {
                category: 'CTA',
                action: 'Click see all courses',
                label: 'homepage',
              },
            })
          }}
          size="lg"
          sx={{
            mx: 'auto',
            position: 'relative',
            w: '100%',
            maxW: '540px',
            cursor: 'pointer',
            fontSize: {
              base: 'md',
              sm: '2xl',
            },
            alignSelf: 'stretch',
            h: { base: '48px', sm: '72px' },
          }}
        >
          <Icon
            as={Background}
            sx={{
              position: 'absolute',
              left: '-6px',
              top: '-6px',
              h: '100%',
              w: 'auto',
              zIndex: -1,
            }}
          />
          ดูคอร์สทั้งหมด
          <Icon
            as={Foreground}
            sx={{
              position: 'absolute',
              left: '-6px',
              top: '-6px',
              h: '100%',
              w: 'auto',
            }}
          />
        </Button>
        <ScrollLink url={{ pathname: 'currentPath', hash: 'contact' }}>
          <Text
            sx={{
              cursor: 'pointer',
              fontSize: {
                base: 'md',
                sm: 'lg',
                md: '2sl',
              },
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            สอบถามข้อมูลเพิ่มเติม
          </Text>
        </ScrollLink>
      </VStack>
    </VStack>
  )
}

export default Label
