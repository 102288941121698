import { ReactElement } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react'

import { logEvent } from '@/lib/stats'

import { Container, Section } from './styles'

import { LandingPageContent } from '../../constants'
import { SectionProps } from '../../types'

type Props = {} & SectionProps

export const FAQ = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section>
      <Box
        id={id}
        sx={{ pos: 'relative', top: ['-55px', '-60px', '-40px', '0'] }}
      />
      <Container
        sx={{
          pt: ['99px', '182px', '182px', '243px'],
          pb: ['134px', '198px', '198px', '260px'],
        }}
      >
        <Text as="h1" textStyle="h1" sx={{ mb: ['16px', '48px'] }}>
          คำถามที่พบบ่อย
        </Text>

        <Accordion allowToggle>
          <Stack spacing={['12px', '16px']}>
            {LandingPageContent['faq']['lists'].map((li) => {
              return <AccordionWrapper key={li.title} {...li} />
            })}
          </Stack>
        </Accordion>
      </Container>
    </Section>
  )
}

type AccordionWrapperProps = {
  title: string
  content: string | ReactElement
  gtmLabel?: string
  isDisabled?: boolean
}

const AccordionWrapper = (
  props: AccordionWrapperProps,
): ReactElement | null => {
  const { title, content, gtmLabel, isDisabled } = props

  if (isDisabled) {
    return null
  }

  return (
    <AccordionItem
      sx={{
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'primary.800',
        outline: 'none !important',
        overflow: 'hidden',
      }}
    >
      <AccordionButton
        _expanded={{
          bg: 'primary.800',
          color: 'white',
        }}
        onClick={
          gtmLabel
            ? () => {
                logEvent({
                  ga: {
                    category: 'FAQ',
                    action: 'Click FAQ',
                    label: gtmLabel,
                  },
                })
              }
            : undefined
        }
        sx={{ minH: ['48px', '80px'], fontSize: ['16px', '24px'] }}
      >
        <Box flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel
        _expanded={{ border: '1px solid', borderColor: 'primary.800' }}
        sx={{
          py: ['16px'],
          px: ['22px'],
          fontWeight: 300,
          fontSize: ['16px', '20px'],
        }}
      >
        {content}
      </AccordionPanel>
    </AccordionItem>
  )
}
