import {
  Box,
  Flex,
  Grid,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import Image from 'next/image'

import { Section, Container } from './styles'

import { LandingPageContent } from '../../constants'
import { SectionProps } from '../../types'

type Props = {} & SectionProps

export const Guarantee = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section id={id}>
      <Container
        layerStyle="landingContainer"
        sx={{
          pt: ['95px', '188px', '332px', '188px'],
          pb: ['87px', '164px', '264px', '164px'],
        }}
      >
        <Text
          as="h1"
          textStyle="h1"
          sx={{
            mb: ['12px', '22px'],
            br: {
              d: ['block', 'block', 'none', 'none'],
            },
          }}
        >
          มั่นใจได้เมื่อเรียนกับ <br />
          LEARN PDPA
        </Text>

        <Flex sx={{ justifyContent: ['center'], mb: ['16px'] }}>
          <BlueDashed />
        </Flex>

        <Box
          layerStyle="background"
          sx={{
            backgroundSize: ['301px 301px', '407px 407px'],
            backgroundImage: `url(/images/Landing/Guarantee/bg-logo.svg)`,
            d: 'flex',
            flexFlow: {
              base: 'column-reverse nowrap',
              md: 'row nowrap',
            },
          }}
        >
          <Box>
            <Text
              as="h2"
              sx={{
                color: 'primary.500',
                textAlign: ['center', 'center', 'start', 'start'],
                fontSize: ['18px', '24px'],
                my: ['12px', '24px', '12px', '12px'],
                br: {
                  d: ['none', 'block'],
                },
              }}
            >
              ออกแบบและสอนโดยผู้เชี่ยวชาญด้านกฎหมาย
              <br />
              ที่ได้รับรองเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
            </Text>

            <Box
              sx={{
                fontSize: ['16px', '24px'],
              }}
            >
              <Text
                sx={{
                  textDecor: 'underline',
                  mb: ['8px'],
                }}
              >
                ตัวอย่างผู้สอน
              </Text>
              <Text sx={{ mb: ['8px'] }}>
                ทนายความผู้เชี่ยวชาญด้านกฎหมายคุ้มครอง ข้อมูลส่วนบุคคล (PDPA)
              </Text>

              <List />
            </Box>
          </Box>

          <ImageGrid />
        </Box>
      </Container>
    </Section>
  )
}

const List = () => {
  return (
    <UnorderedList
      sx={{ fontWeight: 300, fontSize: ['16px', '18px'] }}
      styleType="none"
    >
      {LandingPageContent['guarantee']['lists'].map((li) => {
        return (
          <ListItem
            key={li.main}
            sx={{
              pos: 'relative',
              '::before': {
                content: '""',
                width: ['3px'],
                height: ['3px'],
                borderRadius: '50%',
                bg: 'black',
                position: 'absolute',
                top: ['14px'],
                transform: 'translateY(-50%)',
                left: ['-16px'],
              },
            }}
          >
            {li.main}
            {li.sub && (
              <OrderedList>
                {li.sub.map((sub) => {
                  return <ListItem key={sub}>{sub}</ListItem>
                })}
              </OrderedList>
            )}
          </ListItem>
        )
      })}
    </UnorderedList>
  )
}

const BlueDashed = (): ReactElement => {
  return (
    <Box
      sx={{
        w: ['60px'],
        h: ['2px'],
        bg: 'blue.400',
        d: ['block', 'block', 'none', 'none'],
      }}
    />
  )
}

const ImageGrid = (): ReactElement => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: 'repeat(2, 266px)',
        gridTemplateRows: 'repeat(2, 266px)',
        gap: '10px',
        d: ['none', 'none', 'grid', 'grid'],
        mr: 'auto',
        ml: { base: 'auto', md: '40px' },
        mb: { base: '40px', md: '0' },
        pos: 'relative',
      }}
    >
      <Box
        layerStyle="blue-dot"
        sx={{ boxSize: '116px', right: '-35px', bottom: '-35px' }}
      />

      <Box sx={{ pos: 'relative' }}>
        <Image
          src="/images/Landing/Guarantee/guarantee-1.jpg"
          alt="learn-pdpa"
          layout="fill"
          objectFit="contain"
        />
      </Box>
      <Box sx={{ pos: 'relative' }}>
        <Image
          src="/images/Landing/Guarantee/guarantee-2.jpg"
          alt="learn-pdpa"
          layout="fill"
          objectFit="contain"
        />
      </Box>
      <Box sx={{ pos: 'relative', gridColumn: '1/-1' }}>
        <Image
          src="/images/Landing/Guarantee/guarantee-3.jpg"
          alt="learn-pdpa"
          layout="fill"
          objectFit="contain"
        />
      </Box>
    </Grid>
  )
}
