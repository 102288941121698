export function getIndex(x: number, length: number): number {
  return ((x % length) + length) % length
}

export function getPos(
  x: number,
  index: number,
  visibleCount: number,
  itemLength: number,
): [number, boolean] {
  if (x === index) return [0, true]
  const limitRight = index + itemLength / 2

  let _x = x

  if (x > index && x < limitRight) {
    _x = x - index
  } else if (x + itemLength < limitRight) {
    _x = x + itemLength - index
  } else if (x < index && x + itemLength > limitRight) {
    _x = x - index
  } else if (x > limitRight) {
    _x = x - itemLength - index
  }

  const visibleLength = Math.floor(visibleCount / 2)

  if (Math.abs(_x) <= visibleLength) {
    return [_x, true]
  }

  return [_x, false]
}
