import envConfig from '@/lib/env'

interface BaseUrlProps {
  prefix: string
  path: string
  url?: string
}

export function getBaseUrl({
  prefix,
  path,
  url = envConfig.API_URL,
}: BaseUrlProps): string {
  const urlObject = {
    url,
    ...(prefix && { prefix }),
    ...(path && { path }),
  }

  return Object.values(urlObject).join('')
}
