import { ReactElement } from 'react'
import NextLink from 'next/link'
import { Image, Grid, Box, Stack, Button, Link } from '@chakra-ui/react'

import { logEvent } from '@/lib/stats'
import * as externalLinks from '@/routes/external'

const PdpaCore = (): ReactElement => {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', sm: '40% 60%' }}
      border="1px solid #DBEBFF"
      maxW="1113px"
      bgColor="gray.0"
    >
      <Image
        src="/images/Landing/OtherServices/pdpacore-look.jpg"
        h="100%"
        w="100%"
      />

      <Stack
        bg="gray.0"
        p={{ base: '24px 20px', sm: '32px 24px', md: '56px 48px' }}
        spacing={{ base: '12px', sm: '16px', md: '32px' }}
        my="auto"
      >
        <Image
          src="/images/Landing/OtherServices/pdpacore-logo.svg"
          w={{ base: '109.2px', sm: '136.5px', md: '191.1px' }}
        />
        <Stack spacing={{ base: '24px', md: '48px' }}>
          <Stack spacing="16px">
            <Box
              color="secondary.250"
              fontWeight="700"
              fontSize={['18px', '20px', '36px']}
            >
              PDPA Training & Seminars
            </Box>
            <Box
              color="font.1000"
              fontSize={['14px', '16px', '24px']}
              fontWeight="300"
              maxW="476px"
              w="100%"
            >
              จัดฝึกอบรมให้กับบุคลากรภายในองค์กรของคุณ ทั้งในรูปแบบออนไลน์ และ
              on-site โดยทีมที่ปรึกษาทางกฏหมายมืออาชีพ
            </Box>
          </Stack>
          <NextLink passHref href={externalLinks.PDPACORETRAINING.pathname}>
            <Button
              as={Link}
              isExternal
              bgColor="secondary.250"
              boxShadow="4px 4px 15px rgba(0, 0, 0, 0.25)"
              borderRadius="8px"
              minH={{ base: '50px', md: '74px' }}
              h="max-content"
              fontSize={{ base: '18px', md: '24px' }}
              textDecoration="none !important"
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Other Services',
                    action: 'Click pdpa core',
                  },
                })
              }}
              maxW="541px"
              whiteSpace="normal"
              wordwrap="break-word"
            >
              ดูรายละเอียดเพิ่มเติม
            </Button>
          </NextLink>
        </Stack>
      </Stack>
    </Grid>
  )
}

export default PdpaCore
