import { ReactElement, ReactNode } from 'react'
import {
  FormControl as ChakraFormControl,
  FormLabel,
  FormErrorMessage,
  SystemStyleObject,
  Collapse,
} from '@chakra-ui/react'

type Props = {
  id: string
  label?: string
  errorMsg?: string
  isRequired?: boolean
  children: ReactNode
  sx?: SystemStyleObject
}

export const FormControl = (props: Props): ReactElement => {
  const { label, id, errorMsg, isRequired = false, children, sx } = props

  const isInvalid = Boolean(errorMsg)

  return (
    <ChakraFormControl
      id={id}
      isRequired={isRequired}
      isInvalid={isInvalid}
      sx={sx}
    >
      {label && <FormLabel sx={{ fontWeight: 500 }}>{label}</FormLabel>}
      {children}
      <Collapse in={isInvalid}>
        <FormErrorMessage sx={{ color: 'red.300' }}>
          {errorMsg}
        </FormErrorMessage>
      </Collapse>
    </ChakraFormControl>
  )
}
