'use client'
import { VStack, Text } from '@chakra-ui/react'

import CourseListItem from '@/features/Courses/components/CourseListItem'
import { logEvent } from '@/lib/stats'
import { Carousel } from '@/lib/carousel'
import { Course } from '@/features/Courses/types'

interface Props {
  id?: string
  courses: Course[]
}

export function CourseList({ courses, id }: Props): React.ReactElement {
  return (
    <VStack
      id={id}
      layerStyle="landingContainer"
      spacing={{
        base: 8,
        md: 10,
      }}
      sx={{
        py: {
          base: '80px',
          md: '100px',
        },
      }}
    >
      <Text as="h1" textStyle="h1" sx={{ mb: ['4px', '16px', '0', '0'] }}>
        คอร์สของเรา
      </Text>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Carousel>
          {courses.map((course) => {
            return (
              <CourseListItem
                key={course.title}
                course={course}
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'CTA',
                      action: 'Click our courses',
                      label: course.slug,
                    },
                  })
                }}
              />
            )
          })}
        </Carousel>
      </div>
    </VStack>
  )
}
