import { ReactElement } from 'react'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'

import { Container, Section } from './styles'
import { LandingPageContent } from '../../constants'
import { SectionProps } from '../../types'

type Props = {} & SectionProps

export const WhatYouWillGet = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section>
      <Box id={id} sx={{ pos: 'relative', top: ['-35px', '-30px', '-80px'] }} />
      <Container sx={{ pt: ['115px'], pb: ['90px', '90px', '90px', '163px'] }}>
        <Text
          as="h1"
          textStyle="h1"
          sx={{
            mb: ['16px', '90px'],
          }}
        >
          สิ่งที่ผู้เรียนจะได้รับ
        </Text>

        <DataGrid />
      </Container>
    </Section>
  )
}

const DataGrid = (): ReactElement => {
  return (
    <Grid
      sx={{
        gap: ['56px', '88px', '62px 90px', '62px 90px'],
        gridTemplateColumns: [
          'unset',
          '1fr 1fr',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
        ],
      }}
    >
      {LandingPageContent['whatyouwillget']['cards'].map((card) => {
        return <Card key={card.title} {...card} />
      })}
    </Grid>
  )
}

const Card = (props: {
  icon: string
  title: string
  detail: ReactElement | string
}): ReactElement => {
  const { icon, title, detail } = props

  return (
    <Flex sx={{ flexDir: 'column', alignItems: 'center' }}>
      <Box
        layerStyle="background"
        sx={{
          boxSize: ['100px', '120px'],
          mb: ['18px', '24px'],
          backgroundImage: `url(${icon})`,
        }}
      />
      <Text textStyle="itemTitle" sx={{ mb: ['8px', '6px'] }}>
        {title}
      </Text>
      <Text textStyle="itemDetail">{detail}</Text>
    </Flex>
  )
}
