import { useState, useEffect } from 'react'
import anime from 'animejs'
import { Box, CSSObject } from '@chakra-ui/react'
import { nanoid } from 'nanoid'

import { ReactComponent as Image1 } from './Decoration-1.svg'
import { ReactComponent as Image2 } from './Decoration-2.svg'
import { ReactComponent as Image3 } from './Decoration-3.svg'
import { ReactComponent as Image4 } from './Decoration-4.svg'
import { ANIME_CONFIG } from './constants'

interface Props {
  animate?: boolean
  sx?: CSSObject
}

export function Decoration1({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          targets: `#animate-${id} path`,
          duration: 800,
          translateX: ['0%', '50%'],
          delay: anime.stagger(500),
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
        },
      }}
    >
      <Image1 />
    </Box>
  )
}

export function Decoration2({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          targets: `#animate-${id} path`,
          duration: 800,
          translateX: ['0%', '-20%'],
          delay: anime.stagger(500),
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
        },
      }}
    >
      <Image2 />
    </Box>
  )
}

export function Decoration3({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 0,
          targets: `#animate-${id} path`,
          duration: 800,
          rotate: [
            { value: '0deg', easing: 'easeInOutQuad', duration: 0 },
            { value: '-45deg', easing: 'easeOutSine', duration: 500 },
            { value: '-90deg', easing: 'easeInOutQuad', duration: 1200 },
          ],
          scale: [
            { value: 1, easing: 'easeInOutQuad', duration: 0 },
            { value: 0.1, easing: 'easeOutSine', duration: 500 },
            { value: 1, easing: 'easeInOutQuad', duration: 1200 },
          ],
          delay: anime.stagger(300, {
            grid: [6, 2],
            from: 'center',
          }),
          direction: 'normal',
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
          transformOrigin: 'center center',
        },
      }}
    >
      <Image3 />
    </Box>
  )
}

export function Decoration4({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 0,
          targets: `#animate-${id} path`,
          duration: 800,
          rotate: [
            { value: '0deg', easing: 'easeInOutQuad', duration: 0 },
            { value: '-45deg', easing: 'easeOutSine', duration: 500 },
            { value: '-90deg', easing: 'easeInOutQuad', duration: 1200 },
          ],
          scale: [
            { value: 1, easing: 'easeInOutQuad', duration: 0 },
            { value: 0.1, easing: 'easeOutSine', duration: 500 },
            { value: 1, easing: 'easeInOutQuad', duration: 1200 },
          ],
          delay: anime.stagger(300, {
            grid: [6, 2],
            from: 'center',
          }),
          direction: 'normal',
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
          transformOrigin: 'center center',
        },
      }}
    >
      <Image4 />
    </Box>
  )
}
