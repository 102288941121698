import { Box, Text, AspectRatio, LinkBox, LinkOverlay } from '@chakra-ui/react'
import NextImage from 'next/image'

import ScrollLink from '@/lib/scroll'

import { SectionProps } from '../../types'

type Props = {} & SectionProps

export function Promotions({ id }: Props): React.ReactElement {
  return (
    <Box
      id={id}
      as="section"
      sx={{
        bgImage: {
          md: 'url("/images/Landing/promotions/bg.svg")',
        },
        bgPosition: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        py: {
          base: '96px',
          sm: '200px',
        },
      }}
    >
      <Box layerStyle="landingContainer">
        <Text
          sx={{
            color: 'primary.800',
            fontWeight: 'bold',
            fontSize: {
              base: '2xl',
              sm: '3xl',
              md: '5xl',
            },
            textAlign: 'center',
            my: 4,
          }}
        >
          โปรโมชั่นพิเศษ
        </Text>
        <LinkBox>
          <LinkOverlay
            as={ScrollLink}
            url={{ pathname: 'currentPath', hash: 'contact' }}
          >
            <AspectRatio
              ratio={636 / 332}
              sx={{ w: '100%', maxW: '800px', mx: 'auto' }}
              layerStyle="actionable"
            >
              <NextImage
                src="/images/Landing/promotions/bundling-sale.svg"
                alt="Promotion bundling sale"
                layout="fill"
              />
            </AspectRatio>
          </LinkOverlay>
        </LinkBox>
      </Box>
    </Box>
  )
}
