import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import Image from 'next/image'

import { FormControl } from '@/components/atoms/FormControl'
import TextInput from '@/components/atoms/TextInput'
import { Section, Container } from './styles'
import { SectionProps } from '../../types'
import { emailSubscribe, sendSlack } from '../../services'
import { logEvent } from '@/lib/stats'

type Props = {} & SectionProps

export const Newsletter = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Section sx={{ p: ['73px 0', '80px', '60px 0 55px', '80px'] }} id={id}>
      <Image
        layout="fill"
        src="/images/Landing/Newsletter/newsletter-bg.jpg"
        alt="newsletter-section-background"
        objectFit="cover"
        className="bg"
      />

      <Container layerStyle="landingContainer">
        <Text
          as="h1"
          textStyle="h1"
          sx={{
            color: 'primary.800',
            mb: ['16px', '16px', '32px', '32px'],
            br: {
              d: ['block', 'none'],
            },
          }}
        >
          กรอกอีเมลเพื่อรับข่าวสาร
          <br />
          เมื่อมีคอร์สเพิ่มเติม
        </Text>

        <Box
          sx={{
            w: ['100%', '100%', '543px', '543px'],
            mx: ['auto'],
          }}
        >
          <EmailForm />
        </Box>
      </Container>
    </Section>
  )
}

type NewsletterInputForm = {
  email: string
  consent: boolean
}

const EmailForm = (): ReactElement => {
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<NewsletterInputForm>({ resolver: zodResolver(schema) })

  const consent = watch('consent')
  const email = watch('email')

  const onSubmit = async (values: NewsletterInputForm) => {
    if (isSubmitting) return

    setLoading(true)

    try {
      await emailSubscribe(values)
      await sendSlack(values)

      logEvent({
        ga: {
          category: 'Contact Us',
          action: 'Subscription Success',
        },
      })

      toast({
        description:
          'ได้รับข้อมูลเรียบร้อย เราจะรีบทำการติดต่อกลับโดยเร็วที่สุด',
        status: 'success',
        position: 'top',
        variant: 'left-accent',
      })
      reset()
    } catch {
      toast({
        description: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
        status: 'error',
        position: 'top',
        variant: 'left-accent',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="16px" sx={{ mb: ['24px'] }}>
        <FormControl
          id="newsletter-email"
          isRequired
          errorMsg={errors.email?.message}
        >
          <TextInput
            name="email"
            type="email"
            placeholder="อีเมล"
            register={register}
            customColor={{
              bgColor: ['#ffffff00', '#ffffff00'],
              color: ['primary.800', 'primary.800'],
              placeholderColor: ['gray.600', 'gray.600'],
              borderColor: ['gray.600', 'gray.600'],
            }}
            isRequired
          />
        </FormControl>

        <CheckboxGroup>
          <Checkbox
            isChecked={Boolean(consent)}
            colorScheme="primary"
            size="md"
            sx={{
              display: 'flex',
              color: 'primary.800',
              alignItems: 'flex-start',
              fontWeight: 300,
              borderColor: 'primary.200',
            }}
            {...register('consent')}
          >
            <Text sx={{ mt: '-2px' }}>
              ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ LearnPDPA
              และบริการอื่นๆจากเรา บริษัทในเครือ บริษัทย่อยและพันธมิตรทางธุรกิจ
              &#8203;
              <span style={{ whiteSpace: 'nowrap' }}>
                (คุณสามารถยกเลิกได้ทุกเมื่อ)
              </span>
            </Text>
          </Checkbox>
        </CheckboxGroup>
      </Stack>

      <Button
        type="submit"
        sx={{ w: ['100%'], h: ['48px', '72px'], fontSize: ['16px', '24px'] }}
        disabled={!consent || !email}
        isLoading={loading}
      >
        ลงทะเบียนรับข่าวสาร
      </Button>
    </form>
  )
}

const schema = z.object({
  email: z
    .string()
    .nonempty({ message: 'จำเป็นต้องกรอก' })
    .email({ message: 'อีเมลไม่ถูกต้อง' }),
})
