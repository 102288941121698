import { Box, chakra, Container as CKContainer } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    w: '100%',
    pos: 'relative',
  },
})

export const Container = chakra(CKContainer, {
  baseStyle: {
    w: ['80%', '80%', '90%', '90%'],
    maxW: '1113px',
  },
})
