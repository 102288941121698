import { Box, VStack, AspectRatio } from '@chakra-ui/react'
import { ReactElement } from 'react'

import { SectionProps } from '../../types'

import Label from './Label'
import ImageRight from './Images/ImageRight'
import ImageLeft from './Images/ImageLeft'
import ImageMiddle from './Images/ImageMiddle'
import Background from './Images/Background'
import {
  Decoration1,
  Decoration2,
  Decoration3,
  Decoration4,
} from './Images/Decoration'

type Props = {} & SectionProps

export const Hero = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <VStack
        id={id}
        layerStyle="landingContainer"
        sx={{
          alignItems: 'stretch',
          my: {
            base: '40px',
            sm: '60px',
            md: '80px',
          },
        }}
      >
        <AspectRatio
          ratio={{ base: 3 / 2, md: 20 / 9 }}
          sx={{
            my: {
              base: 6,
              sm: 8,
              md: 10,
            },
          }}
        >
          <Box sx={{ position: 'relative', overflow: 'visible !important' }}>
            <Background
              dataSet={1}
              sx={{
                w: { base: '30%', md: '28%' },
                position: 'absolute',
                right: '-5%',
                bottom: 0,
              }}
            />
            <Background
              dataSet={2}
              sx={{
                w: { base: '30%', md: '28%' },
                position: 'absolute',
                left: '-5%',
                bottom: 0,
              }}
            />
            <Background
              dataSet={0}
              sx={{
                w: { base: '55%', md: '50%' },
                position: 'absolute',
                top: '-5%',
              }}
            />
            <Decoration1
              sx={{ w: '15%', position: 'absolute', top: '10%', left: '25%' }}
            />
            <Decoration2
              sx={{ w: '15%', position: 'absolute', top: '0', right: '25%' }}
            />
            <Decoration3
              sx={{
                w: '2.5%',
                position: 'absolute',
                bottom: {
                  base: '5%',
                  md: '25%',
                },
                left: {
                  base: '40%',
                  md: 'unset',
                },
                right: {
                  base: 'unset',
                  md: '0',
                },
                transform: {
                  base: 'rotate(90deg)',
                  md: 'none',
                },
              }}
            />
            <Decoration4
              sx={{
                w: '2.5%',
                position: 'absolute',
                bottom: {
                  base: '-2%',
                  md: '60%',
                },
                left: {
                  base: 'unset',
                  md: '8%',
                },
                right: {
                  base: '40%',
                  md: 'unset',
                },
                transform: {
                  base: 'rotate(90deg)',
                  md: 'none',
                },
              }}
            />
            <ImageRight
              sx={{
                w: { base: '25%', md: '22%' },
                position: 'absolute',
                right: '5%',
                bottom: 0,
              }}
            />
            <ImageLeft
              sx={{
                w: { base: '25%', md: '22%' },
                position: 'absolute',
                left: '5%',
                bottom: 0,
              }}
            />
            <ImageMiddle
              sx={{
                w: { base: '45%', md: '40%' },
                maxW: '400px',
                position: 'absolute',
                top: 0,
              }}
            />
          </Box>
        </AspectRatio>
        <Label />
      </VStack>
    </Box>
  )
}
