import { useState, useEffect } from 'react'
import { Box, HStack, CSSObject } from '@chakra-ui/react'
import { useSprings } from 'react-spring'

import { AnimatedBox, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

interface Props {
  onClick: (index: number) => void
  length: number
  index: number
  sx?: CSSObject
  id?: string
}

function CarouselBar({
  length,
  index,
  onClick,
  id,
  sx,
}: Props): React.ReactElement {
  const [previousIndex, setPreviousIndex] = useState(index)

  useEffect(() => {
    setPreviousIndex(index)
  }, [index])

  const springs = useSprings(
    length,
    Array(length)
      .fill(0)
      .map((_, _index) => {
        const spring = {
          transform: `translate3d(${_index > index ? '-' : ''}100%, 0, 0)`,
          opacity: 0,
          config: DEFAULT_SPRING_CONFIG,
          immediate: false,
        }

        if (index !== _index && previousIndex !== _index) {
          return spring
        }

        const direction = index - previousIndex

        if (index === _index) {
          spring.opacity = 1
          if (direction > 0) {
            spring.transform = 'translate3d(100%, 0, 0)'
          } else if (direction < 0) {
            spring.transform = 'translate3d(-100%, 0, 0)'
          } else {
            spring.transform = 'translate3d(0%, 0, 0)'
          }
        }

        if (direction > 0) {
          spring.transform = 'translate3d(-100%, 0, 0)'
        } else if (direction < 0) {
          spring.transform = 'translate3d(100%, 0, 0)'
        }

        return spring
      }),
  )

  return (
    <HStack
      spacing={{
        base: 2,
        sm: 3,
      }}
      id={id}
      sx={sx}
    >
      {springs.map((styles, index) => {
        return (
          <Box
            key={`${index}`}
            as="figure"
            sx={{
              position: 'relative',
              overflow: 'hidden',
              w: {
                base: 8,
                sm: 12,
              },
              h: {
                base: 1,
                sm: 2,
              },
              borderRadius: 'full',
              bg: 'gray.200',
              cursor: 'pointer',
              transform: 'translate3d(0, 0, 0)',
            }}
            onClick={() => onClick(index)}
          >
            <AnimatedBox
              style={styles}
              sx={{
                position: 'absolute',
                w: 'full',
                h: 'full',
                bg: 'blue.400',
              }}
            />
          </Box>
        )
      })}
    </HStack>
  )
}

export default CarouselBar
