import { useState, useEffect } from 'react'
import anime from 'animejs'
import { Box, CSSObject } from '@chakra-ui/react'
import { nanoid } from 'nanoid'

import { ReactComponent as Image } from './ImageRight.svg'
import { ANIME_CONFIG } from './constants'

interface Props {
  animate?: boolean
  sx?: CSSObject
}

function ImageRight({ animate = true, sx }: Props): React.ReactElement {
  const [id, setId] = useState<string | undefined>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  useEffect(() => {
    if (id && animate) {
      const animInstances: anime.AnimeInstance[] = []

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          targets: `#animate-${id} #mouth`,
          d: [
            'M2024.58,994.796c-103.091,-0 -143.646,-98.125 -143.646,-99.817c-1.7,-5.075 0,-10.154 5.071,-11.846c5.067,-1.691 10.138,0 11.821,5.075c1.683,5.08 45.625,106.588 157.171,84.596c5.071,-1.696 10.137,1.688 10.137,6.767c1.688,5.075 -1.683,10.15 -6.75,10.15c-11.841,3.383 -23.666,5.075 -33.804,5.075Z',
            'M2016.97,1020.35c-103.092,0 -136.028,-123.677 -136.028,-125.369c-1.7,-5.075 0,-10.154 5.071,-11.846c5.067,-1.691 10.138,0 11.821,5.075c1.683,5.08 45.625,106.588 157.171,84.596c5.071,-1.696 10.137,1.688 10.137,6.767c1.688,5.075 1.709,19.593 -3.358,19.593c-9.86,12.897 -34.677,21.184 -44.814,21.184Z',
          ],
          duration: anime.random(50, 200),
          endDelay: anime.random(50, 200),
          loop: false,
          complete: (anim) => {
            anim.duration = anime.random(2, 5) * 100
            anim.delay = anime.random(50, 200)
            anim.restart()
          },
        }),
      )

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          endDelay: 100,
          duration: 800,
          targets: `#animate-${id} #hammer`,
          rotate: ['0deg', '-15deg'],
          transformOrigin: ['100% 100%', '100% 100%'],
          easing: 'easeInElastic(1, .6)',
        }),
      )

      animInstances.push(
        anime({
          ...ANIME_CONFIG,
          targets: `#animate-${id} #head`,
          transformOrigin: ['50% 50%', '50% 50%'],
          rotate: ['0deg', '2deg'],
          duration: anime.random(50, 200),
          endDelay: anime.random(50, 200),
          loop: true,
          loopComplete: (anim) => {
            anim.duration = anime.random(5, 10) * 100
            anim.delay = anime.random(50, 200)
          },
        }),
      )

      return () =>
        animInstances.forEach((instance: anime.AnimeInstance) =>
          instance.pause(),
        )
    }
  }, [id, animate])

  return (
    <Box
      id={id && `animate-${id}`}
      sx={{
        ...sx,
        '& *': {
          transformBox: 'fill-box',
        },
      }}
    >
      <Image />
    </Box>
  )
}
export default ImageRight
