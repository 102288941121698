import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

import envConfig from '@/lib/env'
import { getBaseUrl } from './helpers'

type FetchParams = {
  url?: string
  prefix?: string
  path: string
} & Omit<AxiosRequestConfig, 'baseURL'>

export function fetchAPI({
  url = envConfig.API_URL,
  prefix = '/api/v1',
  path,
  ...options
}: FetchParams): AxiosPromise {
  return axios({
    baseURL: getBaseUrl({ url, prefix, path }),
    ...options,
  })
}
