import {
  Box,
  Flex,
  InputGroup,
  Text,
  InputProps,
  HStack,
  useTheme,
  useBreakpointValue,
  Textarea as CKTextarea,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { get } from 'lodash'
import { UseFormRegister } from 'react-hook-form'

import { AnimatedBox, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

const AnimatedTextarea = animated(CKTextarea)

interface Props extends InputProps {
  value?: any
  customSize?: Record<string, string>
  register: UseFormRegister<any>
  name: string
  customColor?: {
    bgColor: [string, string]
    color: [string, string]
    placeholderColor: [string, string]
    borderColor: [string, string]
  }
}

function Textarea(props: Props): React.ReactElement {
  const {
    value,
    placeholder,
    isRequired,
    customSize = {
      base: 'lg',
      md: 'xl',
    },
    customColor = {
      bgColor: ['#ffffffff', '#ffffff00'],
      color: ['primary.800', 'blue.400'],
      placeholderColor: ['gray.400', 'white'],
      borderColor: ['white', 'white'],
    },
    sx,
    register,
    height: heightProps,
    ...rest
  } = props

  const { ref: registeredRef, ...registeredProps } = register(props.name)

  const theme = useTheme()

  const size = useBreakpointValue(customSize)

  const fieldStyle = theme.components.Input.sizes[size as string]?.field
  const height = heightProps || fieldStyle?.h || 10

  const color = [
    get(theme.colors, customColor.color[0], customColor.color[0]),
    get(theme.colors, customColor.color[1], customColor.color[1]),
  ]
  const placeholderColor = [
    get(
      theme.colors,
      customColor.placeholderColor[0],
      customColor.placeholderColor[0],
    ),
    get(
      theme.colors,
      customColor.placeholderColor[1],
      customColor.placeholderColor[1],
    ),
  ]
  const bgColor = [
    get(theme.colors, customColor.bgColor[0], customColor.bgColor[0]),
    get(theme.colors, customColor.bgColor[1], customColor.bgColor[1]),
  ]

  const [isFocused, setFocused] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const showPlaceholderAsLabel = Boolean(
    isFocused || value || inputRef.current?.value,
  )

  const spring = useSpring({
    color: showPlaceholderAsLabel ? color[1] : color[0],
    placeholderColor: showPlaceholderAsLabel
      ? placeholderColor[1]
      : placeholderColor[0],
    fontSize: showPlaceholderAsLabel
      ? get(theme.fontSizes, fieldStyle?._placeholder?.fontSize)
      : get(theme.fontSizes, fieldStyle?.fontSize),
    transform: showPlaceholderAsLabel
      ? 'translate3d(0, -100%, 0)'
      : 'translate3d(0, -0%, 0)',
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <Box
      sx={{
        h: 'fit-content',
        position: 'relative',
        borderRadius: 'base',
        height,
      }}
    >
      <HStack
        spacing="0"
        sx={{
          ...fieldStyle,
          position: 'absolute',
          top: '0',
          left: '0',
          px: 0,
          alignItems: 'stretch',
          w: 'full',
          height: 'full',
        }}
      >
        <Box
          style={{
            minWidth: `calc(${fieldStyle?.px} - 6px)`,
            maxWidth: `calc(${fieldStyle?.px} - 6px)`,
            flex: `0 0 calc(${fieldStyle?.px} - 6px)`,
          }}
          sx={{
            borderTopLeftRadius: 'md',
            borderBottomLeftRadius: 'md',
            border: '1px solid',
            borderRight: 'none',
            borderColor: customColor.borderColor,
          }}
        />
        <Flex
          sx={{
            transformOrigin: 'left center',
            flex: '0 0 auto',
            borderBottom: '1px solid',
            borderTop: showPlaceholderAsLabel ? 'none' : '1px solid',
            borderColor: customColor.borderColor,
          }}
        >
          <AnimatedBox
            style={{
              color: spring.placeholderColor,
              fontSize: spring.fontSize,
              transform: spring.transform,
            }}
            onClick={() => {
              inputRef.current?.focus()
            }}
            sx={{
              transformOrigin: 'left top',
              zIndex: 1,
              height: 'fit-content',
              my: '0.75em',
            }}
          >
            <Text
              sx={{
                fontSize: 'inherit',
                px: 2,
                color: 'inherit',
                fontWeight: 'light',
              }}
            >
              {placeholder}{' '}
              {isRequired && (
                <Text as="span" sx={{ color: 'red.400' }}>
                  *
                </Text>
              )}
            </Text>
          </AnimatedBox>
        </Flex>
        <Box
          sx={{
            borderTopRightRadius: 'md',
            borderBottomRightRadius: 'md',
            border: '1px solid',
            borderLeft: 'none',
            borderColor: customColor.borderColor,
            flex: '1 1 100%',
          }}
        />
      </HStack>
      <InputGroup
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          height,
        }}
      >
        <AnimatedTextarea
          {...rest}
          {...registeredProps}
          ref={(e) => {
            registeredRef(e)
            inputRef.current = e
          }}
          placeholder=""
          onFocus={() => {
            !props.isDisabled && setFocused(true)
          }}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            !props.isDisabled && setFocused(false)
            registeredProps.onBlur(e)
          }}
          sx={{
            ...fieldStyle,
            resize: 'none',
            bgColor: 'transparent',
            transition: 'none',
            color: color[1],
            height,
            pt: '0.5em',
            backgroundColor: !showPlaceholderAsLabel ? bgColor[0] : bgColor[1],
            ...sx,
          }}
          variant="ghost"
          size={size}
        />
      </InputGroup>
    </Box>
  )
}

export default Textarea
