import { fetchAPI } from '@/lib/api'
import { ContactFormInput } from './types'
import env from '@/lib/env'

export const sendEmail = async (form: ContactFormInput): Promise<any> => {
  return fetchAPI({
    url: env.API_URL,
    prefix: '/api',
    method: 'post',
    path: '/contact',
    data: form,
  })
}

export const emailSubscribe = async (form: { email: string }): Promise<any> => {
  return fetchAPI({
    url: env.API_URL,
    prefix: '/api',
    method: 'post',
    path: '/contact',
    data: form,
  })
}

export const sendSlack = async (
  form: Partial<ContactFormInput>,
): Promise<any> => {
  return fetchAPI({
    url: env.API_URL,
    prefix: '/api',
    method: 'post',
    path: '/contact-slack',
    data: form,
  })
}
